<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card :class="$vuetify.breakpoint.mobile ? 'pa-6 pt-3 mt-3' : 'pa-10 mt-5'" class="mx-auto" color="secondary"
        >
          <v-card-title
          ><h2
              :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
              class="white--text text-h2 font-weight-bold word-wrapper"
          >
            Welcome back
          </h2></v-card-title
          >
          <div class="grey lighten-3 rounded-lg">
            <v-card-title>
              <v-row>
                <v-col>
                  <v-btn block class="mr-2" color="primary" disabled
                  >
                    <v-icon class="white--text">mdi-google
                    </v-icon
                    >
                    <span class="white--text">Google</span></v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn block color="primary" disabled
                  >
                    <v-icon class="white--text">mdi-facebook
                    </v-icon
                    >
                    <span class="white--text">Facebook</span></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" @submit.prevent>
                <v-text-field
                    v-model="loginInfo.email"
                    :rules="rules"
                    class="rounded-pill"
                    label="Username/Email"
                    solo
                />
                <v-text-field
                    v-model="loginInfo.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="rules"
                    :type="showPassword ? 'text' : 'password'"
                    class="rounded-pill"
                    label="Password"
                    solo
                    @click:append="showPassword = !showPassword"
                />
                <v-btn block class="white--text" color="primary" @click="loginUserCall" type="submit"
                >Login
                </v-btn>
              </v-form>
            </v-card-text>
          </div>
        </v-card>
        <div class="my-3">
          <v-btn :to="{ name: 'SignUp' }" color="secondary">Sign Up</v-btn>
          <v-btn disabled text>Forgot Password</v-btn>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  metaInfo: {
    title: "Login",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  data() {
    return {
      loginInfo: {
        email: "",
        password: "",
      },
      showPassword: false,
      rules: [(v) => !!v || "Required Field"],
    };
  },
  methods: {
    ...mapActions('user', ["loginUser"]),
    ...mapActions('alert', ["alerts"]),
    async loginUserCall() {
      if (this.$refs.form.validate()) {
        console.log("An error has occurred");
      }
      let response = await this.loginUser(this.loginInfo);
      if (response["error"] === "none" || response["error"] === undefined) {
        await this.alerts({
          type: "success",
          msg: "Welcome back " + response["usersUid"],
        });
        await this.$router.push({name: "Dashboard"});
      } else if (response["error"] === "stmtFailed") {
        await this.alerts({
          type: "error",
          msg: "Something has gone wrong on our end!",
        });
      } else if (response["error"] === "enterAllFields") {
        await this.alerts({
          type: "error",
          msg: "Please enter in all the information required",
        });
      } else if (response["error"] === "uidNoExist") {
        await this.alerts({
          type: "error",
          msg: "That user seems to not exist",
        });
      } else {
        await this.alerts({
          type: "error",
          msg: "An unknown error has occurred, please try again",
        });
      }
    },
  },
};
</script>
